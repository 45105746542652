<template>
   <a-row :gutter="25" class="mt-4" style="justify-content: center;">
    <a-col
        v-if="dataView.showLoader"
        :xxl="24" 
        :lg="24" 
        :md="24"
        :xs="24">
      <a-spin size="medium" style="position:relative; left: 50%;margin-top: 14rem;"/>
    </a-col>
    <a-col
        v-if="!dataView.showLoader"
        :xxl="14" 
        :lg="14" 
        :md="14"
        :xs="24">
        <a-row class="container-view">
            <a-col class="mb-4" :xxl="24" :lg="24" :md="24" :xs="24" style="text-align: center;">
                <h3 class="title-bold-normal">¡Tu registro de {{ dataView.module }} ha sido exitoso!</h3>
            </a-col>
            <a-col :xs="24">
                <row>
                    <a-col :xxl="{col: 14, offset: 5}" :lg="{col: 14, offset: 5}" :md="24" :xs="24" v-if="dataView.event?.title != null">
                        <span class="title-bold-normal">Nombre del evento:</span>
                        <br />
                    </a-col>
                    <a-col :xxl="{col: 14, offset: 5}" :lg="{col: 14, offset: 5}" :md="24" :xs="24" v-if="dataView.event?.title != null">
                        <span class="title-normal title-gray">{{ dataView.event?.title }}</span>
                    </a-col>
                    <br>
                    <a-col :xxl="{col: 14, offset: 5}" :lg="{col: 14, offset: 5}" :md="24" :xs="24" v-if="dataView.event?.headquarters != null">
                        <span class="title-bold-normal">Sede:</span>
                    </a-col>
                    <template v-if="dataView.event?.headquarters?.length != null">
                        <a-col :xxl="{col: 14, offset: 5}" :lg="{col: 14, offset: 5}" :md="24" :xs="24" v-for="headquarter in dataView.event.headquarters" :key="headquarter">
                            <span class="title-normal title-gray">{{ formatHeadquarter(headquarter) }}</span>
                        </a-col>
                    </template>
                    <br>
                    <a-col :xxl="{col: 14, offset: 5}" :lg="{col: 14, offset: 5}" :md="24" :xs="24" v-if="dataView.event?.event_date != null">
                        <span class="title-bold-normal">Fecha:</span>
                    </a-col>
                    <a-col :xxl="{col: 14, offset: 5}" :lg="{col: 14, offset: 5}" :md="24" :xs="24" v-if="dataView.event?.event_date != null">
                        <span class="title-normal title-gray">{{ formatDate(dataView.event?.event_date) }}. {{dataView.event?.event_hour}}.</span>
                    </a-col>
                    <br />
                    <a-col :xxl="{col: 14, offset: 5}" :lg="{col: 14, offset: 5}" :md="24" :xs="24">
                        <span class="title-bold-normal">Lista de participantes registrados:</span>
                    </a-col>
                    <a-col :xxl="{col: 14, offset: 5}" :lg="{col: 14, offset: 5}" :md="24" :xs="24">
                        <sdCards v-if="dataView.file_loading">
                            <a-spin size="small" style="position:relative; left: 45%;margin-top: 2rem;"/>
                        </sdCards>
                        <span v-else class="title-normal files" @click="handleDownloadFile">
                            <img class="files-icon" :src="require('@/static/img_events/SEJ enventos_adjuntos.svg')" alt="Aricho adjunto">
                            folio_registro_{{ dataView.event?.id }}.pdf
                        </span>
                    </a-col>
                </row>
            </a-col>
        </a-row>
        <a-row class="mt-4">
            <a-col :xxl="24" :lg="24" :md="24" :xs="24" style="text-align: center;">
                <router-link :to="'/'">
                    <button class="btn-outlined-exit">
                        <span class="title-normal"> Volver al inicio </span>
                    </button>
                </router-link>
            </a-col>
        </a-row>
    </a-col>
   </a-row>
</template>

<script>

import { useRoute, useRouter } from "vue-router";
import { reactive } from 'vue';
import { useStore } from 'vuex';
import { formatDate } from '@/utility/utility'
import { getFileFolioMultipleRegister, getFileFolioMultipleRegisterOnSubEvent } from '@/services/home';

export default {
  name: 'ResponseSuccessRegistrations',
  components: {
  },
  async setup() {
    const route = useRoute();
    const router = useRouter();

    const handleDownloadFile = async (showLoading = true) => {
      const eventID = route.params.eventID;
      let response = {};
      if (showLoading)
        dataView.file_loading = true;

      //console.log({ value: route.params.hasSubEvent, tipo:typeof route.params.hasSubEvent });

      response = route.params.hasSubEvent == "true" || route.params.hasSubEvent == true
        ? await getFileFolioMultipleRegisterOnSubEvent(eventID)
        : await getFileFolioMultipleRegister(eventID)

      if (response && response.success !== false) { //Si es blob no tiene propiedad success, si falla success es false
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', "folio_registro_" + eventID + ".pdf");
        // Append to html page
        document.body.appendChild(link);
        // Force download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
      }

      if (showLoading)
        dataView.file_loading = false;
    }

    if(route.params.module == undefined){
        router.push({ path: '/home/events/' })
    } else {
        if(route.params.eventID != undefined){
            handleDownloadFile(false);
        }
    }

    const formatHeadquarter = (headquarter) => {
        switch (headquarter.type) {
            case "face-to-face": {
              return `${headquarter.name}, ${headquarter.street}, ${headquarter.suburb != null ? headquarter.suburb+',' : headquarter.other_suburb ? headquarter.other_suburb+',' : ''} ${headquarter.municipality ? headquarter.municipality+',' : ''} ${headquarter.postal_code ? headquarter.postal_code : ''}`
            }
            case "virtual": {
              return `${headquarter.virtual_platform}, ${headquarter.link}`
            } 
            default :{
              return `${headquarter.virtual_platform}, ${headquarter.link}, ${headquarter.name}, ${headquarter.street},
              ${headquarter.suburb != null ? headquarter.suburb+',' : headquarter.other_suburb ?  headquarter.other_suburb+',' : ''} ${headquarter.municipality ? headquarter.municipality+',' : ''} ${headquarter.postal_code ? headquarter.postal_code+',' : ''}`
            }
        }
    }

    const { dispatch } = useStore();
    dispatch('changeTitleHeader', route.params.module == undefined ? '' : 'Registro como ' + route.params.module);

    const dataView = reactive({
        showLoader: false,
        module: route.params.module,
        event: route.params?.event ? JSON.parse(route.params?.event) : {},
        file_loading: false,
    })

    return {
      dataView,
      handleDownloadFile,
      formatDate,
      formatHeadquarter,
    };
  },
};
</script>
<style scoped>
.container-view{
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 20px;
    height: auto;
    padding: 2rem 1.5rem 2rem 1.5rem;
}
.title-gray{
    color: #7B868C !important;
}
.btn-outlined-exit{
  border: 1px solid #636668;
  color: #636668;
  padding: 5px 15px 5px 15px;
  background: none;
  border-radius: 15px;
  margin-left: 2rem;
  cursor: pointer;
}
.files{
  color: #E8394D;
  font-family: Nutmeg;
  font-size: 11px;
  font-weight: bold;
}
.files:hover{
  cursor: pointer;
}
.files-icon {
  width: 8px;
  margin-right: 8px;
}
</style>
